/* Sidebar Container */
.wrapper {
    width: min(400px, 100%);
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    padding: clamp(20px, 4vw, 30px);
    z-index: 9999;
    background-color: var(--white-color, #fff);
    transition: right 0.3s ease-in-out;
    overflow-y: auto;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);

    /* Hide scrollbar but keep functionality */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.wrapper::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.wrapper[data-open="true"] {
    right: 0;
}

/* Header Section */
.sidebarHeader {
    padding: 20px 0;
    position: sticky;
    top: 0;
    background-color: var(--white-color, #fff);
    z-index: 2;
    border-bottom: 1px solid var(--border-color, rgba(0, 0, 0, 0.1));
}

/* Close Button */
.closeBtn {
    border: 0;
    outline: none;
    background-color: transparent;
    padding: 10px;
    cursor: pointer;
    color: var(--text-color, #333333);
    transition: all 0.2s ease;
    border-radius: 50%;
}

.closeBtn:hover {
    opacity: 0.7;
    background-color: var(--hover-bg, rgba(0, 0, 0, 0.05));
}

.closeBtn:focus-visible {
    outline: 2px solid var(--primary-color, #7620FF);
    outline-offset: 2px;
}

/* Close Icon */
.closeBtn svg {
    width: 12px;
    height: 12px;
    display: block;
}

.closeBtn svg,
.closeBtn svg g,
.closeBtn svg path {
    fill: currentColor;
}

/* Navigation Links */
.ulStyle {
    padding: clamp(20px, 5vw, 40px);
    list-style-type: none;
}

.ulStyle li {
    margin: 20px 0;
    transition: transform 0.2s ease;
}

.ulStyle li:hover {
    transform: translateX(5px);
}

/* Animations */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(100%);
    }
}

/* Touch Device Optimizations */
@media (hover: none) and (pointer: coarse) {
    .closeBtn {
        min-height: 44px;
        min-width: 44px;
    }

    .ulStyle li {
        padding: 8px 0;
    }
}

/* Print Styles */
@media print {
    .wrapper {
        display: none;
    }
}

/* Add these styles at the end of the file */
.submitButton {
    border: none;
    border-radius: 1rem;
    background-color: #580cd2;
    max-width: 220px;
    width: 100%;
    padding: 10px;
    outline: none;
    color: #fff;
    font-family: inherit;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submitButton:hover {
    background-color: #7620FF;
}

.submitButton:disabled {
    background: #ccc;
    cursor: not-allowed;
}

/* Add these new styles */
.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonContainer li {
    width: 100%;
    display: flex;
    justify-content: center;
} 