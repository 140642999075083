.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.closeButton:hover {
  opacity: 1;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-weight: 600;
  font-size: 0.9rem;
}

.input,
.textarea {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.input:focus,
.textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

.submitButton {
  border: none;
  border-radius: 1rem;
  background-color: #580cd2;
  max-width: 220px;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  font-family: inherit;
  margin: 0 auto;
  display: block;
}

.submitButton:hover {
  cursor: pointer;
  background-color: #7620FF;
}

.submitButton:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.buttonHolder {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.formError {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
  border: 1px solid #ffcdd2;
} 