.wrapper {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    background: linear-gradient(to top left,
            rgba(88, 12, 210, 0.5) -10%,
            rgba(243, 189, 248) 50%,
            rgba(242, 179, 0, 0.3) 110%);

    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.content {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 30px;
}

.headerInfo {
    padding: 2rem 0 1rem 0;
}

@media (max-width: 860px) {
    .headerInfo {
        text-align: center;
        margin-top: 50px;
    }
}