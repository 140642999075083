/** =================== MontserratAlt1 =================== **/

/** MontserratAlt1 Thin **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 100;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-Thin.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-Thin.woff") format("woff");
}

/** MontserratAlt1 ExtraLight **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 200;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-ExtraLight.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-ExtraLight.woff") format("woff");
}

/** MontserratAlt1 Light **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 300;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-Light.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-Light.woff") format("woff");
}

/** MontserratAlt1 Regular **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 400;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-Regular.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-Regular.woff") format("woff");
}

/** MontserratAlt1 Medium **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 500;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-Medium.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-Medium.woff") format("woff");
}

/** MontserratAlt1 SemiBold **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 600;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-SemiBold.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-SemiBold.woff") format("woff");
}

/** MontserratAlt1 Bold **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 700;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-Bold.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-Bold.woff") format("woff");
}

/** MontserratAlt1 ExtraBold **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 800;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-ExtraBold.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-ExtraBold.woff") format("woff");
}

/** MontserratAlt1 Black **/
@font-face {
    font-family: "MontserratAlt1";
    font-weight: 900;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlt1-Black.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlt1-Black.woff") format("woff");
}