.wrapper {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 30px;
}

.lightBg {
    background-color: #f9f9f9;
}

.container {
    max-width: 75rem;
    margin: 0 auto;
    padding: 0 0.9375rem;
}

.headerInfo {
    padding: 4.375rem 0 1.875rem 0;
}

.title {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 1.25rem;
}

.subtitle {
    font-size: 0.8125rem;
    line-height: 1.5;
}

.formWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 1.875rem;
    gap: 2.5rem;
    min-height: 31.25rem;
    position: relative;
}

.formContainer {
    width: 45%;
}

.form {
    width: 100%;
    padding: 4.375rem 0 1.875rem 0;
}

.input {
    width: 100%;
    background-color: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
    border-bottom: 0.0625rem solid #707070;
    height: 1.875rem;
    margin-bottom: 1.875rem;
    font-size: 1.25rem;
    font-weight: 800;
}

.input:-webkit-autofill {
    box-shadow: 0 0 0 62.5rem transparent inset !important;
    -webkit-text-fill-color: #000 !important;
    transition: background-color 5000s ease-in-out 0s;
}

.label {
    font-size: 0.8125rem;
    display: block;
    margin-bottom: 0.3125rem;
}

.submitWrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 1.875rem;
}

.submitButton {
    border: none;
    background-color: #580cd2;
    border-radius: 0.75rem;
    max-width: 12.25rem;
    width: 100%;
    padding: 1vw 2vw;
    outline: none;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submitButton:hover {
    background-color: #7620ff;
}

.submitButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.imagesContainer {
    display: flex;
    width: 45%;
    height: 100%;
    gap: 1.25rem;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.imageColumn {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.contactImgBox {
    width: 100%;
    height: calc(50% - 0.625rem);
    position: relative;
}

.contactImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 0.375rem;
}

.imageColumn:first-child {
    justify-content: space-between;
}

.imageColumn:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
}

.offsetImage {
    height: 50%;
    margin: 0;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 0.375rem;
}

.successMessage {
    color: green;
    margin-bottom: 0.9375rem;
}

.errorMessage {
    color: red;
    margin-bottom: 0.9375rem;
}

@media (max-width: 62em) {
    .imagesContainer {
        display: none;
    }

    .formContainer {
        width: 100%;
    }

    .form {
        width: 100%;
        padding: 1.875rem 0;
    }

    .headerInfo {
        text-align: center;
    }

    .formWrapper {
        flex-direction: column;
        align-items: center;
    }

    .submitWrapper {
        justify-content: center;
    }

    .submitButton {
        font-size: 0.875rem;
        padding: 0.75rem;
    }

    /* Prevent zoom on input focus for iOS */
    .input {
        font-size: 1rem; /* Reduce font size on mobile to prevent zoom */
        -webkit-text-size-adjust: 100%; /* Prevent iOS font scaling */
    }

    /* Force consistent viewport */
    .wrapper {
        min-height: -webkit-fill-available;
        padding: 0 1rem; /* Reduce padding on mobile */
    }

    /* Prevent layout shifts */
    .form {
        width: 100%;
        padding: 1.875rem 0;
        /* Prevent margin collapse */
        overflow: hidden;
    }

    /* Ensure inputs maintain their size */
    .input {
        -webkit-appearance: none; /* Remove default iOS styling */
        appearance: none;
        font-size: 16px !important; /* Prevent zoom on iOS */
        margin-bottom: 1.5rem;
        padding: 0.5rem 0;
        min-height: 2.5rem; /* Ensure consistent height */
    }

    /* Prevent form elements from being affected by viewport changes */
    .formWrapper {
        padding-bottom: 1rem;
        gap: 1rem;
        width: 100%;
        /* Prevent unwanted margin changes */
        margin: 0;
    }

    /* Add consistent touch targets */
    .submitButton {
        min-height: 3rem; /* Ensure good touch target size */
        padding: 0.75rem 1.5rem;
        margin: 0;
        /* Prevent iOS default button styling */
        -webkit-appearance: none;
        appearance: none;
    }
}

/* Add viewport-specific fixes */
@supports (-webkit-touch-callout: none) {
    /* iOS-specific fixes */
    .wrapper {
        min-height: -webkit-fill-available;
    }
}

/* Prevent content shift when virtual keyboard appears */
@media (max-width: 62em) and (orientation: portrait) {
    .form {
        position: relative;
        z-index: 1;
    }

    .input:focus {
        position: relative;
        z-index: 2;
    }
}

@media (max-width: 30em) {
    .submitButton {
        font-size: 0.75rem;
        padding: 0.625rem;
    }
}