.modal {
    display: flex;
    position: fixed;
    /* min-width: 500px; */
    z-index: 99;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    overflow: auto;
    /* background-color: rgba(0, 0, 0, 0.4); */
    justify-content: start;
    align-items: flex-end;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .modalContent {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: auto;
    max-width: 500px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 10px;
  }
  
  .buttonContainer {
    display: flex;
    flex: 1 1 auto;
    gap: 2vw;
    /* hug content */
    /* justify-content: flex-end; */
    margin-top: 20px;
  }
  
  @media screen and (max-width: 600px){
    .modal {
      padding: 10vw;
      min-width: reset;
      justify-content: center;
    }
    .modalContent {
      width: 100%;
      /* border-radius: 0; */
    }
    
  }