.wrapper {
  width: 100%;
  padding: 20px 0;
}

.innerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--container-width, 1200px);
  margin: 0 auto;
  padding: 0 20px;
}

.logoText {
  margin-left: 15px;
}

@media (max-width: 768px) {
  .innerWrapper {
    flex-direction: column;
    text-align: center;
  }

  .logoText {
    margin: 10px 0;
  }
}

@media (max-width: 550px) {
  .innerWrapper {
    padding: 0 10px;
  }
} 