.wrapper {
    padding-top: 100px;
    width: 100%;
    max-height: 100vh;
    position: relative;
    padding-inline: clamp(1rem, 4vw, 2rem);
    display: flex;
    align-items: center;
}

.leftSide {
    width: 50%;
    height: 100%;
}

.rightSide {
    width: 50%;
    height: 100%;
}

.headerP {
    font-size: clamp(14px, 1.5vw, 16px);
    font-weight: 600;
    max-width: 470px;
    padding: 15px 0 30px 0;
}

.btnWrapper {
    max-width: 190px;
}

.imageWrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
}

.img {
    width: auto;
    height: 65vh;
    aspect-ratio: 295/420;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
    position: relative;
    z-index: 3;
}

.quoteWrapper {
    position: absolute;
    left: 0;
    bottom: 50px;
    max-width: 330px;
    padding: 30px;
    z-index: 4;
}

.quotesWrapper {
    position: absolute;
    left: -20px;
    top: -10px;
}

.dotsWrapper {
    position: absolute;
    right: -100px;
    bottom: 100px;
    z-index: 1;
}

@media (max-width: 1024px) {
    .wrapper {
        padding-top: 80px;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
        min-height: 0;
        height: auto;
        max-height: 100vh;
        overflow: hidden;
    }

    .leftSide {
        width: 100%;
        order: 2;
        margin: 10px 0;
        text-align: center;
    }

    .rightSide {
        width: 100%;
        order: 1;
        margin-top: 0;
    }

    .imageWrapper {
        width: auto;
        justify-content: center;
        height: calc(min(50vh, 100vh - 350px));
        display: flex;
        align-items: center;
    }

    .img {
        height: calc(min(50vh, 100vh - 350px));
        width: calc((min(50vh, 100vh - 350px)) * 295 / 420);
        max-width: 90vw;
        object-fit: cover;
    }

    .quoteWrapper,
    .dotsWrapper {
        display: none;
    }

    .btnWrapper {
        margin: 0 auto;
    }

    .headerP {
        text-align: center;
        margin: 0 auto;
        max-width: 100%;
        padding: 10px 0 15px 0;
        font-size: clamp(14px, 3.5vw, 16px);
        line-height: 1.4;
    }

    h1 {
        font-size: clamp(20px, 4vw, 25px) !important;
        line-height: 1.3;
    }

    .headerP {
        font-size: clamp(15px, 3.5vw, 18px);
        line-height: 1.4;
    }
}


/* Safe area insets for notched devices */
@supports (padding: max(0px)) {
  .wrapper {
    padding-left: max(20px, env(safe-area-inset-left));
    padding-right: max(20px, env(safe-area-inset-right));
  }
} 

@media (max-width: 768px) {


    .imageWrapper {
        height: min(50vh, calc(100vw * 420/295)); 
    }

    .img {
        height: min(50vh, calc(100vw * 420/295));
        width: calc(min(50vh, calc(100vw * 420/295)) * 295/420);
        max-width: 90vw;
        object-fit: cover;
    }

    h1 {
        font-size: clamp(18px, 3.5vw, 20px) !important;
    }

    .headerP {
        font-size: clamp(14px, 3vw, 15px);
    }
}
