@font-face {
    font-family: 'Khula';
    font-display: swap;
    /* other font properties */
}

/* CSS Reset and Base Styles */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* CSS Variables */
:root {
  /* Layout */
  --container-width: 1220px;
  --container-padding: clamp(1rem, 4vw, 30px);
  
  /* Colors */
  --color-white: #fff;
  --color-black: #0B093B;
  --color-primary: #7620FF;
  --color-orange: #F2B300;
  --color-grey: #707070;
  --color-light: #F5F5F5;
  
  /* Shadows */
  --shadow-default: 0 0 11px rgba(237, 232, 237, 0.5);
  
  /* Transitions */
  --transition-default: 0.3s ease;
}

/* Base Styles */
html, body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: var(--color-white);
  color: var(--color-black);
  font-family: 'Khula', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
  color: var(--color-black);
  transition: color var(--transition-default);
}

a:hover {
  color: var(--color-primary);
}

/* Layout */
.container {
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--container-padding);
}

/* Typography */
.regular { font-weight: 400; }
.semiBold { font-weight: 600; }
.extraBold { font-weight: 800; }

/* Font Sizes */
.font11 { font-size: 0.688rem; }
.font12 { font-size: 0.75rem; }
.font13 { font-size: 0.813rem; }
.font14 { font-size: 0.875rem; }
.font15 { font-size: 0.938rem; }
.font18 { font-size: 1.125rem; }
.font20 { font-size: 1.25rem; }
.font25 { font-size: 1.563rem; }
.font30 { font-size: 1.875rem; }
.font40 { font-size: 2.5rem; }
.font60 { 
  font-size: 3.75rem;
  line-height: 1.2;
}

/* Flexbox Utilities */
.flex { display: flex; }
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn { flex-direction: column; }

/* Text Utilities */
.textCenter { text-align: center; }
.textLeft { text-align: left; }
.textRight { text-align: right; }

/* Interactive Elements */
.pointer { cursor: pointer; }
.animate { transition: var(--transition-default); }

/* Colors */
.darkColor { color: var(--color-black); }
.purpleColor { color: var(--color-primary); }
.orangeColor { color: var(--color-orange); }
.greyColor { color: var(--color-grey); }
.whiteColor { color: var(--color-white); }

/* Backgrounds */
.whiteBg { background-color: var(--color-white); }
.purpleBg { background-color: var(--color-primary); }
.darkBg { background-color: var(--color-black); }

/* Active States */
.active {
  border-bottom: 2px solid var(--color-primary);
  color: var(--color-primary);
}

/* Responsive Typography */
@media only screen and (max-width: 859px) {
  .font60 { font-size: 2.8rem; }
  .font40 { font-size: 1.875rem; }
  .font30 { font-size: 1.5rem; }
  .font25 { font-size: 1.25rem; }
  .font20 { font-size: 1.125rem; }
  .font18 { font-size: 0.938rem; }
  .font15 { font-size: 0.813rem; }
  .font14 { font-size: 0.813rem; }
  .font13 { font-size: 0.75rem; }
  .font12 { font-size: 0.688rem; }
  .font11 { font-size: 0.688rem; }
}

/* Form Elements */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--color-white) inset;
  -webkit-text-fill-color: var(--color-black);
}

/* Border Radius */
.radius8 { border-radius: 1rem; }
.radius6 { border-radius: 0.6rem; }

/* Print Styles */
@media print {
  .container {
    max-width: 100%;
    padding: 0;
  }
}
